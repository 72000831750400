import React from 'react';
import TopSection from './TopSection';
import ExchangesSection from './ExchangesSection';
import JournalingSection from './JournalingSection';
import AnalyticsSection from './AnalyticsSection';
import VerificationSection from './VerificationSection';
import ImportSection from './ImportSection';
import GetStartedSection from './GetStartedSection';
import SecuritySection from './SecuritySection';
import Testimonials from '@/components/Shared/Testimnonials';
import StartConnectingSection from '@/components/Shared/StartConnecting';
import Seo from '@/components/Seo';
import { ROUTES } from '@/lib/constants.mjs';
import { QA, Tweet } from '@/types/generalTypes';
import useDisableDraftMode from '@/hooks/useDisableDraftMode';
import JsonLd from '@/components/JsonLd';
import { useTranslation } from 'next-i18next';

interface HomeProps {
    tweets: {
        home: Tweet[];
        top: Tweet[];
    };
    unlockedQA: QA[];
    contactQA: QA[];
    draftMode: boolean;
}

const Home: React.FC<HomeProps> = ({ tweets, draftMode, unlockedQA, contactQA }) => {
    const { t } = useTranslation('home', { keyPrefix: 'seo' });
    const seo = {
        route: ROUTES.HOME,
        title: t('title' as any),
        image: '/images/seo/home.png',
        description: t('description' as any),
    };

    useDisableDraftMode(draftMode);
    const jsonLdData = {
        '@context': 'https://schema.org/',
        '@type': 'FAQPage',
        mainEntity: [...unlockedQA, ...contactQA].map((item) => {
            return [
                {
                    '@type': 'Question',
                    name: item.questionName,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: item.acceptedAnswerText,
                    },
                },
            ];
        }),
    };
    return (
        <>
            <Seo {...seo} />
            <JsonLd data={jsonLdData} />
            <TopSection tweets={tweets?.top || []} />
            <ExchangesSection />
            <JournalingSection />
            <AnalyticsSection />
            <VerificationSection />
            <ImportSection />
            <GetStartedSection />
            <SecuritySection />
            <Testimonials tweets={tweets?.home || []} />
            <StartConnectingSection />
        </>
    );
};

export default Home;
